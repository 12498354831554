body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
	/* both of these were added for the footer to work as expected */
	position: relative;
	min-height: 100%;
}

main {
	overflow: hidden; /* added for footer to work as expected */
}

.page {
	margin-bottom: 60px; /* added for footer */
}

.footer {
	/* This class is so the footer looks and acts correctly */
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 60px; /* Set the fixed height of the footer here */
	line-height: 60px; /* Vertically center the text there */
	background-color: #c1c1c3;
	text-align: center;
	color: #393E41;
}

#heroVid {
  /* position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%; */
  	height: calc(100vh - 60px);
	width: 100%;
  	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: fixed;
}

.hero {
	/* Need to replace with litteraly anything else probably */
	/* background-image: url('https://tuck-portfolio.s3-us-west-2.amazonaws.com/hero-night.jpg'); */
	height: calc(100vh - 60px);
	width: 100%;

	/* Position and center the image to scale nicely on all screens */
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}

/* Place text in the middle of the image */
.hero-text {
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	/* color: #21897E; */
	/* font-family: 'Roboto'; */
	/* font-weight: 200; */
}